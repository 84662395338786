// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // apiUrl: 'http://localhost:55687/',
    apiUrl: 'https://test.chemhazel.com/',
    userName: 'james@hazel.com',
    password: '123456',
    isTrial: false,
    toxicityRed: 12,
    toxicityOrange: 18,
    toxicityGreen: 34,
    exposureRed: 3,
    exposureOrange: 6,
    exposureGreen: 9,
    bubbleChartValue1: 0,
    bubbleChartValue2: 0.16,
    bubbleChartValue3: 0.25,
    bubbleChartValue4: 0.36,
    bubbleChartValue5: 1,
    bubbleChartColorValue1: '#93B863',
    bubbleChartColorValue2: '#495E3C',
    bubbleChartColorValue3: '#D74E09',
    bubbleChartColorValue4: '#B80C09',
    doiUrl: "https://doi.org/",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
